import { useLocale } from "vuetify";

export default function () {
  const { $i18n } = useNuxtApp();

  function buildImageUrl (path: string): string {
    const config = useRuntimeConfig();
    return `${config.public.baseUrl}${path || config.public.not_found_image_url}`;
  }

  const getBackEndLocale = computed<string>(() => {
    // @ts-ignore
    const locale = $i18n.locale.value;
    return locale === "cz" ? "cs" : locale;
  });

  function roundDistance (meters: number): string {
    const nf = Intl.NumberFormat(useLocale().current.value, {
      maximumSignificantDigits: 2
    });

    if (meters < 1000) {
      return `${nf.format(meters)} m`;
    } else {
      return `${nf.format(meters / 1000.0)} km`;
    }
  }

  function formatDateHumanReadable (date: string = "", locale: string = "cs-CZ"): string {
    if (!date) {
      return "";
    }
    const dateObject = new Date(date);
    return useDateFormat(dateObject, "DD.MM.YYYY", { locales: locale }).value;
  }

  function formatDateToISOString (date: Date): string {
    let month = String(date.getMonth() + 1);
    if (month.length < 2) {
      month = `0${month}`;
    }
    let day = String(date.getDate());
    if (day.length < 2) {
      day = `0${day}`;
    }
    return `${date.getFullYear()}-${month}-${day}`;
  }

  function formatLocaleDateTime (dateObject: Date | string, datetimeFormat = "HH:mm:ss"): string {
    const date = new Date(dateObject);
    let dateLocale = "cs-CZ";
    switch ($i18n.locale.value) {
    case "sk":
      dateLocale = "sk-SK";
      break;
    case "en":
      dateLocale = "en-GB";
      break;
    }
    return useDateFormat(date, datetimeFormat, { locales: dateLocale }).value;
  }

  /** For ["one", "two", "three"] returns "one, two and three" */
  function localizeList (values: string[]): string {
    if (values.length === 0) {
      return "";
    }
    if (values.length === 1) {
      return values[0];
    }

    return values.slice(0, -1).join(", ") + $i18n.t("general._and_") + values[values.length - 1];
  }

  /** For ["one", "two", "three"] returns `${t('one')}, ${t('two')} and ${t('three')}` */
  function translateAndLocalizeList (messages: string[]): string {
    return localizeList(messages.map(msg => $i18n.t(msg)));
  }

  return {
    getBackEndLocale,
    buildImageUrl,
    roundDistance,
    formatDateToISOString,
    localizeList,
    formatDateHumanReadable,
    formatLocaleDateTime,
    translateAndLocalizeList
  };
}
