export default function () {
  function prepareURLParams (url: string, params: object): string {
    const paramRegex = /{([a-zA-Z]+)}/g;
    const urlParams = (url.match(paramRegex) || []).map(param => param.replace("{", "").replace("}", ""));
    let newURL = url;
    urlParams.forEach((param) => {
      if (params[param as keyof typeof params] === undefined || params[param as keyof typeof params] === null) {
        newURL = newURL.replace(`{${param}}/`, "");
      } else {
        newURL = newURL.replace(`{${param}}`, params[param as keyof typeof params]);
      }
    });

    return newURL;
  }

  return {
    prepareURLParams
  };
}
