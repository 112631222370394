import { merge, transform } from "lodash-es";
import type { FetchError } from "ofetch";
import type { _AsyncData } from "#app/composables/asyncData";

export default function () {
  const runtimeConfig = useRuntimeConfig();

  const baseUrl = computed<string>(() => import.meta.client ? runtimeConfig.public.baseApiUrl : "http://api:8000");

  async function call<DataT> (url: string, options: object = {}) {
    const { getBackEndLocale } = useUtils();
    const authStore = useAuthStore();

    let internalOptions: object = {
      headers: {
        "Accept-Language": getBackEndLocale.value,
        accept: "application/json",
        "X-Region": runtimeConfig.public.region
      }
    };

    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }

    if (authStore.isLoggedIn) {
      internalOptions = merge(internalOptions, { headers: { Authorization: authStore.accessTokenHeader } });
    }

    const mergedOptions = merge(internalOptions, options);

    return (await useFetch<DataT>(url, {
      ...mergedOptions,
      baseURL: baseUrl.value
    }))as _AsyncData<DataT, FetchError>;
  }

  /** Prefixes all the URLs in `urls` with the specified `prefix`. */
  // @ts-ignore
  function urlNamespace<UrlsT extends object> (prefix: string, urls: UrlsT): UrlsT {
    // TODO: support nested namespaces
    // @ts-ignore
    return transform(urls, function (res, val, key) {
      // @ts-ignore
      res[key] = (...params) => prefix + val(...params);
    });
  }

  const { prepareURLParams } = useUrlsUtils();

  const commonUrls = {
    auth: urlNamespace("/api/user", {
      LOGIN: () => "/login",
      CHANGE_LANGUAGE: (language: string) => prepareURLParams("/change/language/{language}", { language }),
      TRADEIN_LOGIN: () => "/tradein-login",
      VERIFY_OTP: () => "/verify-otp",
      REFRESH_TOKEN: () => "/refresh-token",
      SESSION: () => "/session",
      LOGOUT: () => "/logout",
      REGISTER_AMBASSADOR: () => "/register/ambassador",
      REGISTER_PARTNER: () => "/register/partner",
      FORGOTTEN_PASSWORD: () => "/forgotten",
      RECOVERY: (uid: string, token: string) => prepareURLParams("/recovery-password/{uid}/{token}", { uid, token }),
      USER_INFO: () => "/get-user-info",
      USER_OTP: () => "/get-otp",
      ARES: () => "/ares",
      CONTACT_US: () => "/contact-us",
      NEW_PHONE_NUMBER: () => "/new-phone-number",
      ALL_CATEGORIES: () => "/categories",
      MULTIMEDIA_BY_CATEGORY: (categoryId: string, page: number) => prepareURLParams(`/categories/${categoryId}/media/?page=${page}`, { categoryId }),
      MARK_AS_ACCESSED: () => "/accessed-media",
      INVITE_AMBASSADOR: () => "/invite-ambassador",
      LOAD_AMBASSADORS: () => "/load-ambassadors",
      PARTNER_AMBASSADOR: () => "/crud-partner-ambassador",
      LOAD_INVITED_AMBASSADORS: () => "/load-invited-ambassadors",
      CRUD_CONTACT_PERSONS: () => "/crud-contact-person",
      LOAD_CONTACT_PERSONS: () => "/load-contact-persons",
      COMPANY_DATA: () => "/company-data",
      REGISTER_IMPORTED_AMBASSADORS: () => "/import-ambassadors",
      VERIFY_IMPORTED_AMBASSADORS: () => "/verify-imported-ambassadors",
      LOAD_ALL_BONUSES: () => "/load-bonuses",
      LOAD_BONUSES: (id: string) => prepareURLParams(`/load-bonuses/${id}`, { id }),
      ACTIVATE_ACCOUNT: () => "/activate-account",
      LOAD_BONUSES_FILTER: () => "/load-bonuses-filter",
      VERIFY_PASSWORDS: () => "/verify-passwords",
      PARTNER_API_KEYS: () => "/partner-api/keys",
      PARTNER_API_KEY: () => "/partner-api/key",
      GET_AMBASSADOR_STORES: (partnerId: string) => prepareURLParams(`/get-ambassador-stores/${partnerId}`, { partnerId })
    }),
    product: urlNamespace("/api/product", {
      QUALITIES: () => "/qualities",
      DEVICE_TYPES_FOR_BUYOUT: () => "/device-types-for-buyout",
      DEVICE_TYPES_FOR_BONUS: () => "/device-types-for-bonus",
      MANUFACTURERS_BY_CATEGORY: (categoryIdentifier: string) => prepareURLParams("/manufacturers-by-category/{categoryIdentifier}", { categoryIdentifier }),
      MANUFACTURERS_FOR_BUYOUT_LIST: () => "/manufacturers-for-buyout-list",
      LIST_MANUFACTURERS: () => "/manufacturers/list",
      META_MASTER_BY_CATEGORY_BY_MANUFACTURER: (categoryIdentifier: string, manufacturerSeoName: string) => prepareURLParams("/meta-masters-by-category-by-manufacturer/{categoryIdentifier}/{manufacturerSeoName}", {
        categoryIdentifier,
        manufacturerSeoName
      }),
      META_MASTER_WITH_HIGHEST_BUYOUT_PRICE: () => "/meta-masters-with-highest-buyout-price",
      ATTR_CHOICES_BY_META_MASTER: (metaMasterId: string) => prepareURLParams("/attr-choices-by-meta-master/{metaMasterId}", { metaMasterId }),
      ATTR_CHOICES_FOR_NEW_BY_META_MASTER: (metaMasterId: string, partnerId: string, userBuyoutCode: string | null, isOther: number) => prepareURLParams("/attr-choices-for-new-by-meta-master/{metaMasterId}/{partnerId}/{userBuyoutCode}/{isOther}", { metaMasterId, partnerId, userBuyoutCode, isOther }),
      COLOR_CHOICES_BY_MASTER_DEVICE: (masterProductId: string) => prepareURLParams("/color-choices-by-master-device/{masterProductId}", { masterProductId }),
      COLOR_CHOICES_BY_MASTER_DEVICE_BY_PARTNER_NEW: (masterProductId: string, partnerId: string) => prepareURLParams("/color-choices-by-master-device-new/{masterProductId}/{partnerId}", { masterProductId, partnerId }),
      QUALITY_CHOICES_BY_COLOR_AND_MASTER: (masterProductId: string, productColorId: string) => prepareURLParams("/quality-choices-by-color-and-master/{masterProductId}/{productColorId}", {
        masterProductId,
        productColorId
      }),
      QUALITY_CHOICES_BUYOUT_FALSE_FOR_BUYOUT: (masterProductId: string, productColorId: string, buyoutId: string) => prepareURLParams("/quality-choices-buyout-false-for-buyout/{masterProductId}/{productColorId}/{buyoutId}", {
        masterProductId,
        productColorId,
        buyoutId
      })
    }),
    buyout: urlNamespace("/api/buyout", {
      UPDATE_CUSTOMER_INFO: () => "/update-customer-info",
      CREATE_BONUS_NEW_DEVICE: () => "/create-bonus-new-device",
      CREATE_PERSONAL_DATA: () => "/create-personal-data",
      UPDATE_BUYOUT: () => "/update-buyout",
      CREATE_DEVICE_IMEI: () => "/create-device-imei",
      BUYOUT_DETAIL: (id: string) => prepareURLParams("/{id}", { id }),
      BUYOUT_DELETE: (id: string) => prepareURLParams("/{id}/delete", { id }),
      MULTIPLE_BUYOUT_DELETE: () => "/multiple-buyout-delete",
      BUYOUT_BONUSES: (id: string) => prepareURLParams("/{id}/bonuses", { id }),
      LIST_TYPE_BONUSES: () => "/type-bonus",
      BUYOUT_NOT_CONTAINED_BONUSES: (id: string) => prepareURLParams("/{id}/not-contained-bonuses/", { id }),
      BUYOUT_NOT_CONTAINED_BONUSES_FOR_PARTNER: (id: string, partnerId: string) => prepareURLParams("/{id}/not-contained-bonuses/{partnerId}", { id, partnerId }),
      UPDATE_BUYOUT_BONUSES: (id: string) => prepareURLParams("/{id}/update-bonuses", { id }),
      BUYOUT_ON_STORE_AVAILABLE: (userBuyoutCode: string) => prepareURLParams("/buyout-on-store-available/{userBuyoutCode}", { userBuyoutCode }),
      BUYOUT_DETAIL_FOR_ON_STORE: (userBuyoutCode: string) => prepareURLParams("/buyout-detail-for-on-store/{userBuyoutCode}", { userBuyoutCode }),
      BUYOUT_ON_STORE_COMPLETE_DETAIL: (userBuyoutCode: string) => prepareURLParams("/buyout-on-store-complete-detail/{userBuyoutCode}", { userBuyoutCode }),
      BUYOUT_ON_STORE: (userBuyoutCode: string, storeId: string) => prepareURLParams("/buyout-on-store/{userBuyoutCode}/{storeId}", { userBuyoutCode, storeId }),
      GET_HANDOVER_PROTOCOL: (userBuyoutCode: string) => prepareURLParams("/get-handover-protocol/{userBuyoutCode}", { userBuyoutCode }),
      LIST_WAITING_BUYOUTS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/list/waiting${paginationQueryParams}`, pageNumber, pageSize, sort),
      LIST_BUYOUTS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/list${paginationQueryParams}`, pageNumber, pageSize, sort),
      LIST_EMAILS: (id: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/buyout/{id}/emails", { id }), pageNumber, pageSize, sort),
      LIST_BONUSES: (pageNumber: number, pageSize: number, sort: string) => paginationQuery("/bonus/list", pageNumber, pageSize, sort),
      LIST_BONUSES_NO_PAGE: () => "/bonus/list",
      LIST_BONUS_MASTERS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery("/bonus-masters/list", pageNumber, pageSize, sort),
      LIST_BONUS_MASTERS_NO_PAGE: () => "/bonus-masters/list",
      ASSIGN_MASTER_BONUS: () => "/bonus-masters/assign",
      EXTEND_BONUSES: () => "/bonuses/extend",
      SET_BONUS_RULES: () => "/bonuses/set-rules",
      SET_BONUS_ACTIVE: () => "/bonuses/set-active",
      LIST_CAMPAIGNS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/campaign/list${paginationQueryParams}`, pageNumber, pageSize, sort),
      LIST_CAMPAIGNS_NO_PAGE: () => "/campaign/list",
      SEND_TO_CLIENT_URL: () => "/send-to-client-url",
      CONCEPT: (id: string) => prepareURLParams("buyout-concept/{id}", { id }),
      GET_CONCEPT: () => ("/buyout-concept/load-concept"),
      LIST_STATUSES: () => "/status/list",
      SET_RECEIVED: () => "/set-received/",
      LIST_PARTNERS: () => "/partners/list",
      LIST_BUYOUT_PARTNERS: () => "/buyout-partners",
      FETCH_QR_LABEL_DATA: (id: string) => prepareURLParams("/qr-label-data/{id}", { id }),
      CONTRACT: (id: string) => prepareURLParams("/contract/{id}", { id }),
      SEND_CONTRACT: (id: string) => prepareURLParams("/send-contract/{id}", { id }),
      DOWNLOAD_CONTRACT: (id: string) => prepareURLParams("/download-contract/{id}", { id }),
      APPROVAL: (id: string) => prepareURLParams("/approval/{id}", { id }),
      REJECT: (id: string) => prepareURLParams("/reject/{id}", { id }),
      DELETE_BONUSES: (ids: string[]) => prepareURLParams("/bonus?ids={ids}", { ids }),
      BONUS_ID: (id: string) => prepareURLParams("/bonus/{id}", { id }),
      BONUS_MASTER_DEVICES: (bonusId: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/bonus-master-devices/{bonusId}", { bonusId }), pageNumber, pageSize, sort),
      BONUS_QUALITIES: (bonusId: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/bonus-qualities/{bonusId}", { bonusId }), pageNumber, pageSize, sort),
      BONUS_DEVICE_TYPES: (bonusId: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/bonus-device-types/{bonusId}", { bonusId }), pageNumber, pageSize, sort),
      BONUS_NEW_DEVICE_TYPES: (bonusId: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/bonus-new-device-types/{bonusId}", { bonusId }), pageNumber, pageSize, sort),
      BONUS_OLD_MANUFACTURERS: (bonusId: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/bonus-old-manufacturers/{bonusId}", { bonusId }), pageNumber, pageSize, sort),
      BONUS_MASTER_ID: (id: string) => prepareURLParams("/master-bonus/{id}", { id }),
      CREATE_BONUS: () => "/create-bonus",
      CREATE_MASTER_BONUS: () => "/create-master-bonus",
      COPY_BONUS: (bonusId: string) => prepareURLParams("/copy-bonus/{bonusId}", { bonusId }),
      CREATE_CAMPAIGN: () => "/campaign/create-campaign",
      CAMPAIGN_ID: (id: string) => prepareURLParams("/campaign/{id}", { id }),
      NEW_DEVICES_PARTNERS_LIST: (userBuyoutCode: string) => prepareURLParams("/new-devices-partners-list/{userBuyoutCode}", { userBuyoutCode }),
      NEW_DEVICES_BONUS_LIST_BY_PARTNER: (userBuyoutCode: string | null, partnerId: string) => prepareURLParams("/new-devices-bonus-list/{userBuyoutCode}/{partnerId}", {
        userBuyoutCode,
        partnerId
      }),
      NEW_DEVICES_OTHERS_MANUFACTURERS: (userBuyoutCode: string | null, partnerId: string, bonusId: string) => prepareURLParams("/new-devices-others-manufacturers/{userBuyoutCode}/{partnerId}/{bonusId}", {
        userBuyoutCode,
        partnerId,
        bonusId
      }),
      NEW_DEVICES_OTHERS_BONUS_LIST_BY_PARTNER_BY_MANUFACTURER_BY_BONUS: (userBuyoutCode: string | null, partnerId: string, manufacturerId: string, bonusId: string) => prepareURLParams("/new-devices-others-bonus-list/{userBuyoutCode}/{partnerId}/{manufacturerId}/{bonusId}", {
        userBuyoutCode,
        partnerId,
        manufacturerId,
        bonusId
      }),
      BONUS_BY_OLD_NEW_BY_PARTNER: (userBuyoutCode: string, masterDeviceId: string, partnerId: string) => prepareURLParams("/bonus-by-old-new/{userBuyoutCode}/{masterDeviceId}/{partnerId}", {
        userBuyoutCode,
        masterDeviceId,
        partnerId
      }),
      BUYOUT_DEFECTS: (buyoutDeviceId: string) => prepareURLParams("/defects/{buyoutDeviceId}", { buyoutDeviceId }),
      SELECTED_BUYOUT_DEFECTS: (buyoutId: string) => prepareURLParams("/{buyoutId}/defects/", { buyoutId }),
      TYPE_BUYOUT_CHECKS: (buyoutDeviceId: string) => prepareURLParams("/buyout-checks/{buyoutDeviceId}", { buyoutDeviceId }),
      BUYOUT_CHECKS: (buyoutId: string) => prepareURLParams("/{buyoutId}/checks/", { buyoutId }),
      BUYOUT_ACCESSORIES: (buyoutId: string) => prepareURLParams("/get-buyout-accessories/{buyoutId}", { buyoutId }),
      BUYOUT_DETAIL_BY_NUMBER: (buyoutId: string) => prepareURLParams("/buyout-detail/{buyoutId}", { buyoutId }),
      ENHANCED_BUYOUT_DETAIL_BY_NUMBER: (buyoutId: string) => prepareURLParams("/enhanced-buyout-detail/{buyoutId}", { buyoutId }),
      BUYOUT_PARTNER_BY_BUYOUT_NUMBER: (buyoutId: string) => prepareURLParams("/buyout-partner/{buyoutId}", { buyoutId }),
      UPDATE_NEW_DEVICE: (buyoutNumber: string) => prepareURLParams("/update-new-device/{buyoutNumber}", { buyoutNumber }),
      SEARCH_MASTER_DEVICES: (buyoutId: string, searchValue: string) => prepareURLParams("/master-devices/{buyoutId}/search?q={searchValue}", { buyoutId, searchValue }),
      SEARCH_MASTER_DEVICES_FOR_BONUS: (typeDeviceId: string, searchValue: string) => prepareURLParams("/master-devices-for-bonus/{typeDeviceId}/search?q={searchValue}", { typeDeviceId, searchValue }),
      BONUS_EDIT_MASTER_DEVICES: (bonusId: string) => prepareURLParams("/bonus-edit-master-devices/{bonusId}", { bonusId }),
      BONUS_EDIT_OLD_MANUFACTURERS: (bonusId: string) => prepareURLParams("/bonus-edit-old-manufacturers/{bonusId}", { bonusId }),
      BONUS_EDIT_QUALITIES: (bonusId: string) => prepareURLParams("/bonus-edit-qualities/{bonusId}", { bonusId }),
      BONUS_EDIT_DEVICE_TYPES: (bonusId: string) => prepareURLParams("/bonus-edit-device-types/{bonusId}", { bonusId }),
      BONUS_EDIT_NEW_DEVICE_TYPES: (bonusId: string) => prepareURLParams("/bonus-edit-new-device-types/{bonusId}", { bonusId }),
      CANCEL_CHANGE_DEVICE: (buyoutId: string) => prepareURLParams("/{buyoutId}/cancel-device-change", { buyoutId }),
      CHANGE_DEVICE: (buyoutId: string, masterDeviceId: string, colorId: string) => prepareURLParams("/{buyoutId}/device/{masterDeviceId}/{colorId}", {
        buyoutId,
        masterDeviceId,
        colorId
      }),
      DEFECTS_BY_DEVICE: (buyoutDeviceColorId: string) => prepareURLParams("/defects/{buyoutDeviceColorId}", { buyoutDeviceColorId }),
      ACCESSORIES_BY_MASTER_DEVICE: (buyoutDeviceDeviceTypeId: string) => prepareURLParams("/available-accessories/{buyoutDeviceDeviceTypeId}", { buyoutDeviceDeviceTypeId }),
      SET_RETURNED: () => "/set-returned/",
      CHANGE_ACCEPTED: (buyoutId: string) => prepareURLParams("/change-accepted/{buyoutId}", { buyoutId }),
      CHANGE_RECEIVE: (buyoutId: string) => prepareURLParams("/change-receive/{buyoutId}", { buyoutId }),
      BOSS_EXPORT: () => "/boss-export",
      LOAD_NEW_DEVICE: (newDeviceId: string) => prepareURLParams("/load-new-device/{newDeviceId}", { newDeviceId }),
      LOAD_BUYOUT_BONUS: (userBuyoutCode: string) => prepareURLParams("/load-buyout-bonus/{userBuyoutCode}", { userBuyoutCode }),
      BUYOUT_REVALUATION_REACTION: (buyoutId: string) => prepareURLParams("/revaluation-reaction/{buyoutId}", { buyoutId }),
      BUYOUT_CONFLICT_REACTION: (buyoutId: string) => prepareURLParams("/conflict-reaction/{buyoutId}", { buyoutId }),
      RESEND_EMAILS: (buyoutId: string, ids: string) => prepareURLParams("/buyout/{buyoutId}/resend-emails?ids={ids}", { buyoutId, ids }),
      BUYOUT_HISTORY_SUMMARY_GRID: () => "/log/list",
      BUYOUT_HISTORY_GRID: (id: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(prepareURLParams("/{id}/log/list", { id }), pageNumber, pageSize, sort),
      IS_NEW_CUSTOMER: (buyoutId: string) => prepareURLParams("/is-new-customer/{buyoutId}", { buyoutId }),
      PAPER_CONTRACT_SIGN: (buyoutId: string) => prepareURLParams("/paper-contract-sign/{buyoutId}", { buyoutId }),
      GET_CUSTOMER_BASIC_INFO: (buyoutId: string) => prepareURLParams("/get-customer-basic-info/{buyoutId}", { buyoutId }),
      LOAD_CUSTOMERS_CONTRACT: (buyoutId: string) => prepareURLParams("/load-customers-contract/{buyoutId}", { buyoutId }),
      CHECK_ACCESS_CODE: () => "/check-access-code/",
      LOAD_CUSTOMER_DATA: (buyoutId: string) => prepareURLParams("/load-customer-data/{buyoutId}", { buyoutId }),
      UPDATE_BUYOUT_PERSONAL_DATA: (buyoutId: string) => prepareURLParams("/update-buyout-personal-data/{buyoutId}", { buyoutId }),
      SIGN_CONTRACT: (buyoutId: string) => prepareURLParams("/sign-contract/{buyoutId}", { buyoutId }),
      SEND_ACCESS_CODE: (buyoutId: string) => prepareURLParams("/send-access-code/{buyoutId}", { buyoutId }),
      SEND_SIGN_CONFIRMATION_CODE: (buyoutId: string) => prepareURLParams("/send-sign-confirmation-code/{buyoutId}", { buyoutId }),
      USER_BUYOUT_ADDRESS: (userBuyoutCode: string) => prepareURLParams("/user_buyout_address/{userBuyoutCode}", { userBuyoutCode }),
      MANUFACTURERS_WITH_MASTER_BONUSES: () => "/manufacturers-with-master-bonuses",
      MANUFACTURERS_WITH_MASTER_BONUSES_FOR_PARTNER: (partnerId: string) => prepareURLParams("/manufacturers-with-master-bonuses/{partnerId}", { partnerId }),
      PARTNERS_WITH_BONUSES: () => "/partners-with-bonuses",
      LIST_LANGUAGES: () => "/languages",
      BUYOUT_NOTE: (id: string) => prepareURLParams("/buyout-note/{id}", { id }),
      COMPETITION_AGREEMENT: () => "/competition-agreement",
      CHECK_PARTNER_BONUS: (id: string) => prepareURLParams("/check-partner-bonus/{id}", { id }),
    }),
    user: urlNamespace("/api/user", {
      USER_DATA: () => "/user-data",
      USER_PRINTER: () => "/user-printer",
      USER_ADDITIONAL_DATA: () => "/user-additional-data",
      COUNTRY_CHOICES_DATA: () => "/country-choices-data",
      UPDATE_USER_DATA: () => "/update-user-data",
      UPDATE_USER_ADDITIONAL_DATA: () => "/update-user-additional-data",
      CHANGE_PASSWORD: () => "/change-password",
      USER_THREE_LATEST_BUYOUTS: () => "/user-three-latest-buyouts",
      USER_THREE_LATEST_BUYOUTS_ON_STORE: () => "/user-three-latest-buyouts-on-store",
      GET_USER_BUYOUTS: () => "/get-user-buyouts",
      GET_USER_BUYOUTS_ON_STORE: () => "/get-user-buyouts-on-store",
      EDUCATION_VIDEOS: () => "/education-videos",
      GET_EMAIL: () => "/get-email",
      PARTNER_DETAIL: (seoName: string) => prepareURLParams("/partner-detail/{seoName}", { seoName }),
      PARTNER_BY_AMBASSADOR_REF: (referenceCode: string) => prepareURLParams("/partner-detail-ref/{referenceCode}", { referenceCode }),
      INVITE_INFO: () => "/invite-info",
      GET_USER_PROGRESS: () => "/get-user-progress",
      LOAD_REWARDS: () => "/load-rewards",
      INFO_REWARD: () => "/info-reward",
      CHANGE_PASSWORD_TRADEIN: () => "/change-password-tradein",
      LIST_PARTNERS_AMBASSADORS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery("/partner/ambassadors", pageNumber, pageSize, sort),
      GET_PARTNERS_AMBASSADOR: (employeeId: string) => prepareURLParams("/partner/ambassadors/{employeeId}", { employeeId }),
      SSO_AUTH_URL: (partnerSeoName: string) => prepareURLParams("/{partnerSeoName}/sso-auth-url", { partnerSeoName }),
      SSO_LOGIN: (partnerSeoName: string) => prepareURLParams("/{partnerSeoName}/sso-login", { partnerSeoName })
    }),
    chat: urlNamespace("/api/ct_chat", {
      USER_CHAT_ROOMS: () => "/chat-rooms",
      CHAT_ROOMS_WITHOUT_MP_STAFF: () => "/chat-rooms-/without-mp-staff",
      CHAT_ROOM_JOIN: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/join", { chatRoomId }),
      CHAT_ROOM_DETAIL: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}", { chatRoomId }),
      CHAT_ROOM_WRITE: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/write", { chatRoomId }),
      CHAT_ROOM_UNREAD_MESSAGES: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/unread-messages", { chatRoomId }),
      CHAT_ROOM_UNREAD_MESSAGES_ANONYMOUS: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/unread-messages/anonymous", { chatRoomId }),
      CHAT_ROOM_JOIN_AND_RETRIEVE: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/join-and-retrieve", { chatRoomId }),
      CHAT_ROOM_MORE_MESSAGES: (chatRoomId: string, oldestMessageId: string) => prepareURLParams("/chat-room/{chatRoomId}/load-more-messages/{oldestMessageId}", {
        chatRoomId,
        oldestMessageId
      }),
      INITIALIZE_CHAT_ROOM: (registrationNumber: string) => prepareURLParams("/initialize-chat-room/{registrationNumber}", { registrationNumber }),
      CHAT_ROOM_MEDIA: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/media", { chatRoomId }),
      UPDATE_LAST_SEEN: (chatRoomId: string) => prepareURLParams("/chat-room/update-last-seen/{chatRoomId}", { chatRoomId }),
      CHAT_ROOMS_ADMIN_GRID: (pageNumber: number, pageSize: number, sort: string) => paginationQuery("/chat-rooms/admin-grid", pageNumber, pageSize, sort),
      CHAT_ROOM_SET_STATUS: (chatRoomId: string) => prepareURLParams("/chat-room/{chatRoomId}/set-status", { chatRoomId }),
    }),
    payment: urlNamespace("/api/ct_payment", {
      LIST_FILTER_DATA: () => "/list-filter-data",
      LIST_PAYMENTS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/list${paginationQueryParams}`, pageNumber, pageSize, sort),
      LIST_EXPORTS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/list-exports${paginationQueryParams}`, pageNumber, pageSize, sort),
      LIST_PAYMENTS_FOR_BUYOUT: (id: string, pageNumber: number, pageSize: number, sort: string) => paginationQuery(`${prepareURLParams("/list/{id}/", { id })}${paginationQueryParams}`, pageNumber, pageSize, sort),
      REQUEST_REWARD_PAYMENT: () => "/request-reward-payment",
      BANK_ACCOUNT_VERIFICATION_DATA: () => "/bank-account-verification-data",
      GENERATE_ORDER_AND_CHANGE_STATUS: () => "/generate-order-and-change-status",
      DOWNLOAD_PAYMENT_ORDER: (id: string) => prepareURLParams("/download-payment-export/{id}", { id }),
      DENY_PAYMENT: () => "/deny-payment",
      AUTHORIZE_PAYMENT: () => "/authorize-payment",
      SET_FOR_PAYMENT: () => "/set-for-payment",
      HAS_USER_PAY_PERMISSION: () => "/has-user-pay-permission",
      VALIDATE_FOR_PAYMENT_CSV: () => "/validate-for-payment-csv",
      IMPORT_FOR_PAYMENT_CSV: () => "/import-for-payment-csv",
      SET_STATUS_BASED_ON_BOSS: () => "/set_status_based_on_boss",
      MARK_PAYMENT_AS_PAID: () => "/mark-payment-as-paid",
      PAYMENT_RETURNED: () => "/payment-returned",
      UPDATE_BANK_ACCOUNT_FROM_BUYOUT: () => "/update-bank-account-from-buyout",
      ADD_SURCHARGE_FOR_BUYOUT: (buyoutId: string) => prepareURLParams("/add-surcharge-for-buyout/{buyoutId}", { buyoutId }),
    }),
    blacklist: urlNamespace("/api/ct_blacklist", {
      LIST_DEVICES: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/list/devices${paginationQueryParams}`, pageNumber, pageSize, sort),
      LIST_USERS: (pageNumber: number, pageSize: number, sort: string) => paginationQuery(`/list/users${paginationQueryParams}`, pageNumber, pageSize, sort),
      DELETE_DEVICES: (ids: string[]) => prepareURLParams("/delete/devices?ids={ids}", { ids }),
      DELETE_USERS: (ids: string[]) => prepareURLParams("/delete/users?ids={ids}", { ids }),
      DEVICE: () => "/device",
      USER: () => "/user",
      IMPORT_DEVICES: () => "/import/devices",
      IMPORT_USERS: () => "/import/users"
    }),
    common: urlNamespace("/api/ct_common", {
      PRINTERS: () => "/printers/",
      SEND_EMAIL: () => "/send-email/",
      BANK_CONFIRMATION_SEND: () => "/bank-confirmation-send/",
      REFERRAL_BUYOUT_OFFER_SEND: () => "/referral-buyout-offer-send/",
      LIST_NOTIFICATION: () => "/notifications/",
      MARK_NOTIFICATION_AS_READ: () => "/notifications/mark_as_read/",
      MARK_ALL_NOTIFICATION_AS_READ: () => "/notifications/mark_all_as_read/",
      CSV_EXAMPLE: (file: string) => prepareURLParams("/csv-example/{file}", { file }),
      SHOW_IBAN: () => "/show-iban/",
      ACTIVE_NOTIFICATION: () => "/type_notifications/active",
      SEND_NOTIFICATION: () => "/send_notification/",
      LIST_REGIONS: () => "/regions-list"
    }),
    faq: urlNamespace("/api/faq", {
      FAQ: () => "/faq"
    }),
    log: urlNamespace("/api/ct_log", {
      REFERRAL_OPEN: () => "/referral-open"
    }),
    export: urlNamespace("/api/ct_export", {
      EXPORT: (exportName: string) => prepareURLParams("/export/{exportName}", { exportName }),
      EXPORT_INFO: () => "/export-info"
    }),
    delivery: urlNamespace("/api/delivery", {
      AVAILABLE_DELIVERY_TYPES: (userBuyoutCode: string) => prepareURLParams("/available-delivery-types/{userBuyoutCode}", { userBuyoutCode }),
      DELIVERY_TYPE_SLOTS: (identifier: string) => prepareURLParams("/delivery-type-slots/{identifier}", { identifier }),
      ORDER_DELIVERY: () => "/order-delivery/",
      ORDER_DELIVERY_LATER: () => "/order-delivery-later/",
      STAFF_ORDER_DELIVERY: () => "/staff-order-delivery/",
    })
  };

  const paginationQueryParams = "?page={pageNumber}&page_size={pageSize}";

  function paginationQuery (url: string, pageNumber: number, pageSize: number, sort: string) {
    const params: {
      pageNumber: number
      pageSize: number
      sort?: string
    } = {
      pageNumber,
      pageSize
    };
    url += paginationQueryParams;
    if (sort !== "") {
      params.sort = sort;
      url += "&sort={sort}";
    }

    return prepareURLParams(url, params);
  }

  return {
    call,
    commonUrls,
    baseUrl
  };
}
